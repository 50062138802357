<template>
  <div class="">
    <div class="d-flex panel-input-search">
      <div class="w-10 mt-2">
        <font-awesome-icon icon="search" class="icon alt" />
      </div>
      <div class="w-100">
        <b-form-input
          id="inline-form-input-name"
          class="mb-2 mr-sm-2 mb-sm-0 w-100 search-input"
          :placeholder="$t('searchPlace')"
          v-model="search"
          @keyup.enter="getAddressList"
        ></b-form-input>
      </div>
    </div>
    <div class="panel-address-list">
      <template v-if="isLoadingList">
        <div
          class="
            text-center
            loading-panel
            d-flex
            align-items-center
            justify-content-center
            bg-white
          "
        >
          <b-spinner
            label="Loading..."
            variant="warning"
            class="m-5 spinner-main"
          ></b-spinner>
        </div>
      </template>
      <template v-else>
        <div v-for="(item, index) in address" :key="index">
          <div
            class="py-1 px-3 border-bottom pointer"
            v-if="index !== 0"
            @click="setAddress(item)"
          >
            <div>
              {{ item.address }}
            </div>
            <div class="f-size-14 text-postal">
              {{ item.zipCode }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
 
<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    OtherLoading
  },
  props: {
    address: {
      required: true,
      type: Array
    },
    isLoadingList: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      search: "",
      isShowModal: false,
      addressList: []
    };
  },
  created: async function () {},
  methods: {
    getAddressList() {
      this.$emit("getAddressList", this.search);
    },
    setAddress(value) {
      this.$emit("setAddress", value);
    }
  }
};
</script>
<style lang="scss" scoped>
.w-10 {
  width: 10%;
}
.search-input {
  border: 0;
  border-bottom: 1px solid #bcbcbc;
  border-radius: 0;
  box-shadow: none;
}
.search-input:focus {
  box-shadow: none;
}
.panel-address-list {
  height: calc(100vh - 100px);
  overflow: auto;
}
.text-postal {
  color: #838588;
}
.loading-panel {
  height: calc(100vh - 200px);
}
</style>