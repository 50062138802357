<template>
  <div>
    <b-sidebar
      id="sidebar-term"
      aria-labelledby="sidebar-cat-header"
      ref="termSidebar"
      backdrop
      shadow
      right
      :title="$t('searchLocation2')"
      v-model="isShowModal"
    >
      <div class="px-3 py-2">
        <AddressPanel
          :address="address"
          :isLoadingList="isLoadingList"
          v-on:setAddress="setAddress"
          v-on:getAddressList="getAddressList"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AddressPanel from "@/components/register/AddressPanel";
export default {
  components: {
    AddressPanel
  },
  props: {
    address: {
      required: true,
      type: Array
    },
    isLoadingList: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      isShowModal: false
    };
  },
  methods: {
    getAddressList(search) {
      this.$emit("getAddressList", search);
    },
    setAddress(value) {
      this.$emit("setAddress", value);
      this.isShowModal = false;
    },
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
</style>