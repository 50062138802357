import { render, staticRenderFns } from "./SidebarAddress.vue?vue&type=template&id=632049d1&scoped=true&"
import script from "./SidebarAddress.vue?vue&type=script&lang=js&"
export * from "./SidebarAddress.vue?vue&type=script&lang=js&"
import style0 from "./SidebarAddress.vue?vue&type=style&index=0&id=632049d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632049d1",
  null
  
)

export default component.exports