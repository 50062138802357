<template>
  <vue-bottom-dialog
    :height="modalHeight + 155"
    v-model="isShowModal"
    backgroundColor="#FFFFFF"
    overlayColor="rgb(0 0 0 / 50%)"
    :hideIndent="true"
    topRadius="0px"
  >
    <div>
      <div class="modal-address-header py-3 px-2 d-flex">
        <div>
          <font-awesome-icon
            icon="arrow-left"
            class="icon alt"
            size="sm"
            @click="isShowModal = false"
          />
        </div>
        <div class="text-center w-100">{{ $t("searchLocation2") }}</div>
      </div>
      <div class="modal-address-content px-3">
        <AddressPanel
          v-on:setAddress="setAddress"
          :address="address"
          :isLoadingList="isLoadingList"
          v-on:getAddressList="getAddressList"
        />
      </div>
    </div>
  </vue-bottom-dialog>
</template>
 
<script>
import AddressPanel from "@/components/register/AddressPanel";
export default {
  props: {
    address: {
      required: true,
      type: Array
    },
    modalHeight: {
      required: true,
      type: Number
    },
    isLoadingList: {
      required: true,
      type: Boolean
    }
  },
  components: {
    AddressPanel
  },
  data() {
    return {
      search: "",
      isShowModal: false
    };
  },

  methods: {
    getAddressList(search) {
      this.$emit("getAddressList", search);
    },
    setAddress(value) {
      this.$emit("setAddress", value);
      this.isShowModal = false;
    },
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.w-10 {
  width: 10%;
}
.search-input {
  border: 0;
  border-bottom: 1px solid #bcbcbc;
  border-radius: 0;
  box-shadow: none;
}
.search-input:focus {
  box-shadow: none;
}
.panel-address-list {
  height: calc(100vh - 100px);
  overflow: auto;
}
.text-postal {
  color: #838588;
}
</style>