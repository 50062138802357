<template>
  <vue-bottom-dialog
    :height="modalHeight + 100"
    v-model="isShowModal"
    backgroundColor="#FFFFFF"
    overlayColor="rgb(0 0 0 / 50%)"
    :hideIndent="true"
  >
    <div>
      <div class="modal-bottom-header main-bg-color p-3 text-white f-size-18">
        {{ textHeader }}
      </div>
    </div>
  </vue-bottom-dialog>
</template>
 
<script>
export default {
  props: {
    textHeader: {
      required: true,
      type: String
    },
    modalHeight: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      isShowModal: false
    };
  },

  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-bottom-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>