<template>
  <div class="w-100 my-5" ref="infoBox">
    <div class="pb-4 text-center text-white font-weight-bold f-size-22">
      <div>{{ $t("msgKerryClubMember") }}</div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="card p-3 mx-3 login-card mb-5">
        <div class="f-size-22 text-center mb-4 font-weight-bold text-gray-1">
          {{ $t("personalInformation") }}
        </div>
        <b-row>
          <b-col md="6">
            <InputText
              :textFloat="$t('firstname')"
              :placeholder="$t('firstname')"
              type="text"
              name="firstname"
              isRequired
              v-model="form.firstName"
              :isValidate="v.firstName.$error"
              :v="v.firstName"
            />
          </b-col>
          <b-col md="6">
            <InputText
              :textFloat="$t('lastname')"
              :placeholder="$t('lastname')"
              type="text"
              name="lastName"
              isRequired
              v-model="form.lastName"
              :isValidate="v.lastName.$error"
              :v="v.lastName"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <InputText
              :textFloat="$t('email')"
              :placeholder="$t('email')"
              type="text"
              name="email"
              isRequired
              v-model="form.email"
              :isValidate="v.email.$error"
              :v="v.email"
            />
          </b-col>
          <b-col md="6">
            <label for="birthdate" class="text-label"
              >{{ $t("birthdate") }} <span class="text-danger">*</span></label
            >
            <datetime
              v-model="form.birthdate"
              class="date-picker-input theme-orange"
              format="dd/MM/yyyy"
              value-zone="Asia/Bangkok"
            ></datetime>
          </b-col>
        </b-row>

        <b-row class="mb-3 mt-2">
          <b-col>
            <b-form-radio-group
              v-model="form.gender"
              :options="genderOptions"
              class="my-2"
              value-field="name"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col class="d-flex justify-content-center" md="6">
            <b-button-group class="register-custom-buttongroup">
              <b-button
                :class="{ active: !isCitizenId }"
                @click="$emit('setCritizenIdType', false)"
                >{{ $t("critizenId") }}</b-button
              >
              <b-button
                :class="{ active: isCitizenId }"
                @click="$emit('setCritizenIdType', true)"
                >{{ $t("passport") }}</b-button
              >
            </b-button-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <InputText
              v-if="!isCitizenId"
              textFloat=""
              :placeholder="$t('critizenId2')"
              @onKeypress="isNumber($event)"
              type="tel"
              name="citizenId"
              isRequired
              v-model="form.citizenId"
              :isValidate="v.citizenId.$error"
              :v="v.citizenId"
            />
            <InputText
              v-if="isCitizenId"
              textFloat=""
              :placeholder="$t('passport2')"
              type="text"
              name="passport"
              isRequired
              v-model="form.passport"
              :isValidate="v.passport.$error"
              :v="v.passport"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <InputText
              :textFloat="$t('address')"
              :placeholder="$t('address')"
              type="text"
              name="address"
              isRequired
              v-model="form.address"
              :isValidate="v.address.$error"
              :v="v.address"
            />
          </b-col>
          <b-col md="6">
            <label for="birthdate" class="text-label"
              >{{ $t("address2") }} <span class="text-danger">*</span></label
            >
            <b-button class="btn-address w-100" @click="onSetOpenModalAddress"
              ><div class="d-flex justify-content-between text-left">
                {{ selectedAddress.address }}
                <div class="my-auto">
                  <font-awesome-icon
                    icon="chevron-right"
                    class="icon alt"
                    size="sm"
                    color="#F37021"
                  />
                </div></div
            ></b-button>

            <div v-if="v.postalCode.$error" class="text-error mt-1">
              {{ $t("msgValidateRequired") }}
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-checkbox
              id="checkbox-term"
              v-model="form.isAcceptTerms"
              name="checkbox-term"
              @change="toggleConsent"
            >
              {{ $t("acceptTerm") }}
              <u
                class="main-color pointer"
                @click="onOpenExternalTermAndCondition()"
                >{{ $t("termAndCondition") }}
              </u>
              {{ $t("and") }}
              <u
                class="main-color pointer"
                @click="onOpenExternalPrivacyPolicy()"
                >{{ $t("privacyPolicy") }}
              </u>
              (require).
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-checkbox
              id="checkbox-PRterm"
              v-model="form.isAcceptRRTerms"
              name="checkbox-PRterm"
            >
              {{ $t("msgConsent1") }}
              <u class="pointer" @click="onOpenExternalTermAndCondition()">
                {{ $t("termAndCondition2") }}
              </u>
              {{ $t("msgConsent2") }}
              <u class="pointer" @click="onOpenExternalPrivacyPolicy()"
                >{{ $t("msgConsent3") }}
              </u>
              {{ $t("msgConsent4") }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <div class="mt-4">
          <b-button
            class="w-100 btn-main py-2"
            @click="handleNextStep"
            :disabled="isDisble || !form.isAcceptTerms"
            ><div>{{ $t("signUp3") }}</div></b-button
          >
        </div>
      </div>
    </div>
    <ModalTermAndCondition
      ref="termModal"
      :textHeader="textHeader"
      :modalHeight="modalHeight"
    />
    <ModalAddress
      ref="addressModal"
      :modalHeight="modalHeight"
      :address="address"
      :isLoadingList="isLoadingList"
      v-on:setAddress="setAddress"
      v-on:getAddressList="getAddressList"
    />
    <SidebarTermAndCondition ref="termSidebar" :textHeader="textHeader" />
    <SidebarAddress
      :address="address"
      :isLoadingList="isLoadingList"
      v-on:setAddress="setAddress"
      v-on:getAddressList="getAddressList"
      ref="addressSidebar"
    />
  </div>
</template>

<script>
import ModalAddress from "@/components/register/modal/ModalAddress";
import ModalTermAndCondition from "@/components/register/modal/ModalTermAndCondition";
import SidebarAddress from "@/components/register/sidebar/SidebarAddress";
import SidebarTermAndCondition from "@/components/register/sidebar/SidebarTermAndCondition";
import InputText from "@/components/input/InputText";
import { mapGetters } from "vuex";
export default {
  components: {
    InputText,
    ModalAddress,
    SidebarAddress,
    ModalTermAndCondition,
    SidebarTermAndCondition
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    isRegister: {
      required: true,
      type: Boolean
    },
    isDisble: {
      required: true,
      type: Boolean
    },
    isCitizenId: {
      required: true,
      type: Boolean
    },
    address: {
      required: true,
      type: Array
    },
    isLoadingList: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      textHeader: "",
      modalHeight: 0,
      selectedAddress: {
        address: `${this.$t("searchLocation3")}`,
        district: null,
        province: null,
        subDistrict: null,
        zipCode: null
      },
      genderOptions: [
        {
          id: 1,
          name: `${this.$t("male")}`
        },
        {
          id: 2,
          name: `${this.$t("female")}`
        },
        {
          id: 3,
          name: `${this.$t("otherGender")}`
        }
      ]
    };
  },
  validations() {
    return {};
  },
  computed: {
    ...mapGetters({
      userAddress: "getAddress"
    })
  },
  created: async function () {
    if (this.form.postalCode) {
      this.setAddressAfterRegisterError();
    }
  },
  mounted() {
    this.matchHeight();
  },
  methods: {
    onOpenExternalTermAndCondition() {
      if (this.$liff.isInClient()) {
        this.$liff.openWindow({
          url: this.$termUrl,
          external: true
        });
      } else {
        window.open(this.$termUrl, "_blank").focus();
      }
    },
    onOpenExternalPrivacyPolicy() {
      if (this.$liff.isInClient()) {
        this.$liff.openWindow({
          url: this.$policyUrl,
          external: true
        });
      } else {
        window.open(this.$policyUrl, "_blank").focus();
      }
    },
    setAddressAfterRegisterError() {
      let address = `${this.form.subdistrict},${this.form.district},${this.form.province},${this.form.postalCode}`;
      this.selectedAddress = {
        address: address,
        district: this.form.district,
        province: this.form.province,
        subDistrict: this.form.subdistrict,
        zipCode: this.form.postalCode
      };
    },
    getAddressList(search) {
      this.$emit("getAddressList", search);
    },
    setAddress(value) {
      this.selectedAddress = value;
      this.$emit("setAddress", value);
    },
    matchHeight() {
      let height = this.$refs.infoBox.clientHeight;
      this.modalHeight = height;
    },
    onSetOpenModalAddress() {
      if (window.innerWidth <= 1199) {
        this.$refs.addressModal.show();
      } else {
        this.$refs.addressSidebar.show(true);
      }
    },
    onSetOpenModal(val) {
      this.textHeader = val;
      if (window.innerWidth <= 1199) {
        this.$refs.termModal.show();
      } else {
        this.$refs.termSidebar.show(true);
      }
    },
    handleNextStep() {
      this.$emit("handleNextStep", 3);
    },
    toggleConsent(checked) {
      this.$emit("toggleConsent", checked);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-label {
  color: #58595b;
  font-size: 15px;
  margin-bottom: 2px;
  font-weight: bold;
}
.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.login-header {
  height: 60px;
  border-bottom: 2px solid white;
}
.login-container {
  height: calc(100vh - 60.5px);
}
.login-card {
  width: 100%;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}
.w-1rem {
  width: 1rem;
  height: 1rem;
}
.btn-address {
  color: #404040;
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  padding: 8px 10px;
}

.btn-address:active,
.btn-address:focus,
.btn-address:hover {
  color: #404040 !important;
  background-color: white !important;
  border: 1px solid #bcbcbc !important;
  border-radius: 6px;
  padding: 8px 10px;
  box-shadow: none !important;
  margin-bottom: 15px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
@media only screen and (min-width: 767.98px) {
  .login-card {
    max-width: 800px;
  }
}
</style>