<template>
  <div
    class="h-100vh position-relative"
    v-bind:style="{
      'background-image':
        'url(' + require('../../../assets/images/BG-Orang-Kerry-Club.jpg') + ')'
    }"
  >
    <!-- <div class="logo-image-container text-center login-header mx-3">
      <img src="@/assets/images/kerry-logo.jpg" alt="Kitten" class="logo-img" />
    </div> -->
    <div class="position-btn-language">
      <b-button class="btn-language ml-2" @click="changeLanguage">
        TH / EN
      </b-button>
    </div>
    <div class="d-flex justify-content-center w-100 login-container">
      <template v-if="registerStep == 1">
        <RegisterPanel
          :form="form"
          :v="$v"
          :isDisble="isDisble"
          :isRegister="isRegister"
          :sessionId="sessionId"
          :activeLanguage="activeLanguage"
          v-on:handleNextStep="handleNextStep"
          v-on:toggleConsent="toggleConsent"
        />
      </template>
      <template v-if="registerStep == 2">
        <UserInformationPanel
          :form="formRegister"
          :v="$v.formRegister"
          :isDisble="isDisble"
          :isRegister="isRegister"
          :isCitizenId="isCitizenId"
          :address="addressList"
          :isLoadingList="isLoadingList"
          v-on:setAddress="setAddress"
          v-on:handleNextStep="handleNextStep"
          v-on:setCritizenIdType="setCritizenIdType"
          v-on:getAddressList="getAddressList"
          v-on:toggleConsent="toggleConsent"
        />
      </template>
      <template v-if="registerStep == 3">
        <RegisterVerifyOTPPanel
          :mobileNo="formRegister.mobileNo"
          v-on:setOTPRef="setOTPRef"
          v-on:handleNextStep="handleNextStep"
        />
      </template>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
      v-on:hadleModalAlertErrorClose="hadleModalAlertErrorClose"
    />
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  maxLength,
  requiredIf
} from "vuelidate/lib/validators";
import RegisterPanel from "@/components/register/RegisterPanel";
import UserInformationPanel from "@/components/register/UserInformationPanel";
import RegisterVerifyOTPPanel from "@/components/register/RegisterVerifyOTPPanel";
import address from "@/assets/dataMockup/Address.json";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
import * as moment from "moment/moment";

const validateCritizen = (critizenType, value) => {
  if (!critizenType && value.length !== 13) {
    return false;
  } else {
    return true;
  }
};

const validatePassport = (critizenType, value) => {
  if (critizenType && value.length !== 9) {
    return false;
  } else {
    return true;
  }
};

const validateMobile = isMember => {
  if (isMember) {
    return false;
  } else {
    return true;
  }
};

export default {
  components: {
    RegisterPanel,
    UserInformationPanel,
    RegisterVerifyOTPPanel,
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      form: { mobileNo: "", password: "" },
      userData: null,
      sessionId: null,
      registerStep: 1,
      isRegister: false,
      isDisble: false,
      isMember: false,
      isCitizenId: false,
      isLoadingList: false,
      address: address,
      modalMessage: "",
      addressList: [],
      isAlreadyMember: false,
      activeLanguage: "th",
      formRegister: {
        userGUID: "",
        otpToken: "",
        otpReference: "",
        otp: "",
        mobileNo: "",
        firstName: "",
        lastName: "",
        citizenId: "",
        passport: "",
        email: "",
        password: "",
        gender: "ชาย",
        birthdate: null,
        address: "",
        subdistrict: "",
        district: "",
        province: "",
        postalCode: "",
        isConsent: false,
        isAcceptTerms: false,
        isAcceptRRTerms: false,
        source: "D-Dots"
      },
    };
  },
  validations() {
    return {
      form: {
        mobileNo: {
          required,
          validateMobile() {
            return validateMobile(this.isAlreadyMember);
          }
        },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6)
        }
      },
      formRegister: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        birthdate: { required },
        citizenId: {
          required: requiredIf(function () {
            return !this.isCitizenId;
          }),
          validateCritizen(value) {
            return validateCritizen(this.isCitizenId, value);
          }
        },
        passport: {
          required: requiredIf(function () {
            return this.isCitizenId;
          }),
          validatePassport(value) {
            return validatePassport(this.isCitizenId, value);
          }
        },
        address: { required },
        subdistrict: { required },
        district: { required },
        province: { required },
        postalCode: { required }
      }
    };
  },
  watch: {
    "$route.fullPath": function () {
      if (this.$route.params.lang) {
        this.activeLanguage = this.$route.params.lang;
      }
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) {
      await this.getUserData();
    } else {
      this.$router.push("/session-expired");
    }
    if (this.$route.params.lang) {
      this.activeLanguage = this.$route.params.lang;
    }
    await this.getAddressList();
  },
  methods: {
    changeLanguage() {      
      let getLanguage = $cookies.get("language");
      getLanguage = getLanguage == "th" ? "en" : "th";
      let finalUrl = `/register/${getLanguage}${decodeURIComponent(
        window.location.search
      )}`;
      this.$router
        .replace(finalUrl)
        // .then(() => {
        //   this.getTopicList();
        // })
        .catch(() => {
          return;
        });
    },
    getAddressList: async function (search) {
      this.isLoadingList = true;
      await this.$axios
        .post(this.$baseUrl + "/kerryapi/PostalCode", { search: search })
        .then(response => {
          if (response.data.result === 1) {
            this.isLoadingList = false;
            this.addressList = response.data.detail;
          }
        });
    },
    hadleModalAlertErrorClose() {
      if (this.isAlreadyMember) {
        this.$router.push("/login?sessionId=" + this.sessionId);
      } else {
        this.registerStep = 2;
        this.$v.form.$reset();
        this.$v.formRegister.$reset();
      }
    },
    hadleModalAlertClose() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$router.push("/login?sessionId=" + this.sessionId);
      }
    },
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.userGUID) {
              this.userData = response.data.detail;
              this.formRegister.userGUID = response.data.detail.userGUID;
            } else {
              this.$router.push("/session-expired");
            }
          }
        });
    },
    setOTPRef(value) {
      this.formRegister.otpToken = value.otpToken;
      this.formRegister.otpReference = value.otpReference;
    },
    setAddress(value) {
      this.formRegister.subdistrict = value.subDistrict;
      this.formRegister.district = value.district;
      this.formRegister.province = value.province;
      this.formRegister.postalCode = value.zipCode;
    },
    moment: function () {
      return moment();
    },
    setCritizenIdType(value) {
      this.isCitizenId = value;
    },
    handleNextStep(step, otp) {
      if (step == 2) {
        this.checkForm();
      } else if (step == 3) {
        this.checkFormRegister();
      } else if (step == 4) {
        this.formRegister.otp = otp;
        this.submitFormRegister();
      }
    },
    toggleConsent(checked) {
      this.formRegister.isAcceptTerms = checked;
      this.formRegister.isConsent = checked;
    },
    checkForm: async function () {
      this.isAlreadyMember = false;
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.sendValidateTelephone();
    },
    checkFormRegister: async function () {
      this.$v.formRegister.$touch();

      if (this.$v.formRegister.$error) return;
      this.registerStep = 3;
    },
    sendValidateTelephone: async function () {
      this.isDisble = true;
      this.isRegister = true;
      this.error = "";
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/Identity`, {
          mobileNo: this.form.mobileNo
        })
        .then(data => {
          this.isDisble = false;
          this.isRegister = false;
          if (data.data.result == 1) {
            this.isAlreadyMember = false;
            this.registerStep = 2;
            this.formRegister.mobileNo = this.form.mobileNo;
            this.formRegister.password = this.form.password;
            this.formRegister.birthdate = moment().format();
            this.$v.formRegister.$reset();
          } else {
            this.isAlreadyMember = true;
            this.registerStep = 1;
            this.modalMessage = this.$t("msgAlreadyMember");
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    submitFormRegister: async function () {
      this.isDisble = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/Register`, this.formRegister)
        .then(data => {
          this.isDisble = false;
          if (data.data.result == 1) {
            this.$cookies.set(
              "kerry-member-token",
              data.data.detail.memberToken,
              60 * 60 * 24 * 30
            );
            this.isDisble = false;
            this.modalMessage = this.$t("successSignup");
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
            if (this.$liff.isInClient()) {
              setTimeout(() => {
                this.$liff.closeWindow();
              }, 1000);
            } else {
              setTimeout(() => {
                window.close();
              }, 1000);
              //this.$router.push("/login?sessionId=" + this.sessionId);
            }
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.login-header {
  height: 60px;
  border-bottom: 2px solid white;
}
.login-container {
  height: calc(100vh - 60.5px);
}
.login-card {
  width: 100%;
}
.position-btn-language {
  position: absolute;
  right: 20px;
  top: 20px;
}
.btn-language {
  background: white !important;
  color: #f37021 !important;
  border: none;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}

@media only screen and (max-width: 430px) {
  .position-btn-language {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@media only screen and (min-width: 767.98px) {
  .login-card {
    max-width: 450px;
  }
}
</style>
