<template>
  <div>
    <b-sidebar
      id="sidebar-term"
      aria-labelledby="sidebar-cat-header"
      ref="termSidebar"
      backdrop
      shadow
      right
      :title="textHeader"
      v-model="isShowModal"
    >
      <div class="px-3 py-2"></div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    textHeader: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isShowModal: false
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>